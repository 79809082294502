
<template>
  <div>
    <header1Component
      :circleActive="1"
      :detailActive="1"
      :loopCircleSmall="4"
      :circleSmall="2"
      :changeCircle="0"
      :changeTitle="0"
      titleHeader="หัวข้อ : 2.รายละเอียดและข้อเสนอโครงการ"
    />
    <div class="container">
      <div class="card2">
        <div class="title conditionCard2">
          ทฤษฎี/งานวิจัย/โครงการที่เกี่ยวข้อง (ให้กรอกข้อมูลหรือแนบไฟล์ pdf
          อย่างใดอย่างหนึ่ง) <font color="red">*</font>
        </div>

        <div class="conditionCard2">
          <textarea v-model="relatedTheoryResearchProject.dataText"></textarea>
        </div>

        <div class="conditionCard2" align="center">
          <div align="center" style="padding-top: 30px; padding-bottom: 10px">
            <input
              type="file"
              @change="uploadImage1($event)"
              id="uploadSingle"
              hidden
            />
            <label class="label" for="uploadSingle">แนบเอกสาร(pdf)</label>
          </div>
        </div>

        <div class="title conditionCard2" style="margin-top: 36px">
          จุดเด่นของผลผลิตของโครงการเทียบกับโครงการที่มีอยู่ในปัจจุบัน
          <font color="red">*</font>
        </div>

        <div class="conditionCard2">
          <textarea v-model="highlightsProjectOutput"></textarea>
        </div>

        <hr />

        <div class="title conditionCard2" style="margin-top: 36px">
          หน่วยงานร่วมดำเนินการ (กรณีโครงการหรือกิจกรรมที่ขอรับการส่งเสริม
          สนับสนุน ให้ความช่วยเหลือ หรือทุนอุดหนุนการวิจัยและพัฒนา
          มีหน่วยงานอื่นร่วมดำเนินดครงการหรือกิจกรรม จะต้องมีหนังสือ
          ยืนยันจากหน่วยงานนั้นๆ
          โดยให้ผู้มีอำนาจหรือผู้มอบอำนาจเป็นผู้ลงนามในหนังสือดังกล่าว)
        </div>

        <div v-for="(item, index) in jointOperationAgencies" :key="index">
          <div class="conditionCard2 grid grid-cols-5 gap-4">
            <div>
              <div class="content" style="color: #242424">ลำดับ</div>
              <div>
                <input type="text" :value="`${index + 1}`" readonly />
              </div>
            </div>

            <div class="col-span-2">
              <div class="content" style="color: #242424">ชื่อหน่วยงาน</div>
              <div>
                <input
                  type="text"
                  placeholder="กรอก"
                  v-model="jointOperationAgencies[index].organizationName"
                />
              </div>
            </div>

            <div class="col-span-2">
              <div class="content" style="color: #242424">หน้าที่</div>
              <div>
                <input
                  type="text"
                  placeholder="กรอก"
                  v-model="jointOperationAgencies[index].duty"
                />
              </div>
            </div>
          </div>

          <div
            class="conditionCard2 content"
            style="color: #242424; margin-top: 15px; margin-bottom: 9px"
          >
            แนบไฟล์
          </div>

          <div class="conditionCard2">
            <div style="padding-bottom: 10px">
              <input
                type="file"
                @change="uploadImage2($event, index)"
                :id="`uploadSingle${index}`"
                hidden
              />
              <div>
                <label class="label" :for="`uploadSingle${index}`"
                  >แนบหนังสือยืนยันจากหน่วยงาน ร่วมดำเนินการ (pdf)
                  <font color="red">*</font>
                </label>
              </div>
            </div>
          </div>

          <div class="conditionCard2" v-if="index === 0">
            <div
              class="flex flex-row items-center justify-start"
              style="height: 50px; margin-top: 36px; margin-bottom: 36px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px"
                @click="addJointOperationAgencies()"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="plus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">
                เพิ่มหน่วยงานร่วมดำเนินการ
              </div>
            </div>
          </div>

          <div class="conditionCard2" v-else>
            <div
              class="flex flex-row items-center justify-start"
              style="height: 50px; margin-top: 36px; margin-bottom: 36px"
            >
              <div
                class="
                  circle
                  flex
                  items-center
                  justify-center justify-items-center
                "
                style="margin-right: 16px; background-color: red"
                @click="deleteJointOperationAgencies(index)"
              >
                <font-awesome-icon
                  style="width: 18px; height: 23px"
                  icon="minus"
                  color="white"
                />
              </div>

              <div class="titleCircle flex items-center">
                ลบหน่วยงานร่วมดำเนินการ
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div class="title conditionCard2">
          ผลลัพธ์ที่คาดว่าจะได้รับจากโครงการ (Outcomes)
          <font color="red">*</font>
        </div>

        <div class="conditionCard2">
          <textarea v-model="expectedResultsProject"></textarea>
        </div>

        <div
          class="title conditionCard2"
          style="margin-top: 32px; margin-bottom: 32px"
        >
          ผู้ใช้ประโยชน์จากโครงการ (ตอบได้มากกว่า 1 ข้อ)
          <font color="red">*</font>
        </div>

        <div class="conditionCard2">
          <div class="flex flex-row items-center" align='right' style="margin-bottom: 16px">
            <div class="content" style="color: #242424; margin-right: 24px; width: 200px;">
              ประชาชน
            </div>
            <div class="w-full">
              <input type="text" placeholder="กรอก" v-model="usersUseProject.people" />
            </div>
          </div>

          <div class="flex flex-row items-center" align='right' style="margin-bottom: 16px">
            <div class="content" style="color: #242424; margin-right: 24px; width: 200px;">
              ภาคประชาชน
            </div>
            <div class="w-full">
              <input type="text" placeholder="กรอก" v-model="usersUseProject.publicSector" />
            </div>
          </div>

          <div class="flex flex-row items-center" align='right' style="margin-bottom: 16px">
            <div class="content" style="color: #242424; margin-right: 24px; width: 200px;">
              หน่วยงานรัฐบาล/ รัฐวิสาหกิจ
            </div>
            <div class="w-full">
              <input type="text" placeholder="กรอก" v-model="usersUseProject.governmentAgenciesStateEnterprise" />
            </div>
          </div>

          <div class="flex flex-row items-center" align='right' style="margin-bottom: 16px">
            <div class="content" style="color: #242424; margin-right: 24px; width: 200px;">
              ภาคการศึกษา
            </div>
            <div class="w-full">
              <input type="text" placeholder="กรอก" v-model="usersUseProject.semester" />
            </div>
          </div>

          <div class="flex flex-row items-center" align='right' style="margin-bottom: 16px">
            <div class="content" style="color: #242424; margin-right: 24px; width: 200px;">
              อื่นๆ โปรดระบุ
            </div>
            <div class="w-full">
              <input type="text" placeholder="กรอก" v-model="usersUseProject.other" />
            </div>
          </div>
        </div>

        <div class="flex flex-row">
          <div
            class="w-1/2 flex flex-start justify-start"
            style="margin-left: 72px; margin-top: 81px"
          >
            <button class="buttonPrevious" @click="createRequest()">
              ก่อนหน้า
            </button>
          </div>

          <div
            class="w-1/2 flex items-end justify-end"
            style="margin-right: 72px; margin-top: 81px"
          >
            <button class="buttonNext" @click="updateRequest()">ถัดไป</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import useRequest from "../../hooks/useRequest";
import useUpload from "../../hooks/useUpload";
import header1Component from "@/components/capital/header1Component";
export default {
  components: {
    header1Component,
  },
  mounted() {
    this.getData();
  },
  methods: {
    uploadImage1(event) {
      this.relatedTheoryResearchProject.dataFile = event.target.files[0];
    },
    uploadImage2(event, index) {
      this.jointOperationAgencies[index].dataFile = event.target.files[0];
    },
    addJointOperationAgencies() {
      this.jointOperationAgencies.push({
        organizationName: null,
        duty: null,
        dataFile: null,
      });
    },
    deleteJointOperationAgencies(id) {
      this.jointOperationAgencies.splice(id, 1);
    },
    async updateRequest() {
      let data1 = new FormData();
      data1.append("fileUpload", this.relatedTheoryResearchProject.dataFile);
      await useUpload.uploadFile(data1).then((response) => {
        if (response.data.file !== "") {
          this.relatedTheoryResearchProject.dataFile = response.data.file;
        }
      });

      for (let i = 0; i !== this.jointOperationAgencies.length; i++) {
        let data2 = new FormData();
        data2.append("fileUpload", this.jointOperationAgencies[i].dataFile);
        await useUpload.uploadFile(data2).then((response) => {
          if (response.data.file !== "") {
            this.jointOperationAgencies[i].dataFile = response.data.file;
          }
        });
      }

      let data = {
        relatedTheoryResearchProject: this.relatedTheoryResearchProject,
        highlightsProjectOutput: this.highlightsProjectOutput,
        jointOperationAgencies: this.jointOperationAgencies,
        expectedResultsProject: this.expectedResultsProject,
        usersUseProject: this.usersUseProject,
      };
      useRequest.updateProject(this.id, data).then((response) => {
        console.log(response.data);
      });
    },
    getData() {
      useRequest.getProjectId(this.id).then((response) => {
        this.relatedTheoryResearchProject =
          response.data.relatedTheoryResearchProject;
        this.highlightsProjectOutput = response.data.highlightsProjectOutput;
        this.jointOperationAgencies = response.data.jointOperationAgencies;
        this.expectedResultsProject = response.data.expectedResultsProject;
        this.usersUseProject = response.data.usersUseProject;
      });
    },
  },
  setup() {
    const relatedTheoryResearchProject = ref({
      dataText: null,
      dataFile: null,
    });
    const highlightsProjectOutput = ref(null);
    const jointOperationAgencies = ref([
      {
        orderId: null,
        organizationName: null,
        duty: null,
        dataFile: null,
      },
    ]);
    const expectedResultsProject = ref(null);
    const usersUseProject = ref({
      people: null,
      publicSector: null,
      governmentAgenciesStateEnterprise: null,
      semester: null,
      other: null,
    });
    return {
      relatedTheoryResearchProject,
      highlightsProjectOutput,
      jointOperationAgencies,
      expectedResultsProject,
      usersUseProject,
    };
  },
  data() {
    return {
      id: this.$route.params.id,
    };
  },
};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.card2 {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 20px;
  padding-top: 34px;
  padding-bottom: 36px;
  margin-bottom: 36px;
}

.conditionCard2 {
  margin-left: 73px;
  margin-right: 73px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;

  color: #242424;
  margin-bottom: 10px;
}

hr {
  margin-left: 33px;
  margin-right: 33px;
  margin-top: 30px;
  margin-bottom: 30px;
  border: 1px solid #c4c4c4;
}

.content {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #575757;
}

.contentActive {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
  width: 300px;
  margin-right: 24px;
  margin-left: 48px;
}

input {
  background: #ffffff;
  opacity: 0.66;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding-left: 15px;
}

button {
  width: 195px;
  height: 50px;
  color: white;
  background: #573c81;
  border-radius: 5px;
}

.buttonPrevious {
  width: 195px;
  height: 50px;
  color: #573c81;
  background: #ffffff;
  border: 1px solid #573c81;
  box-sizing: border-box;
  border-radius: 5px;
}

textarea {
  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  height: 200px;
  padding-left: 18px;
  padding-top: 10px;
}

.label {
  width: 420px;
  height: 51px;
  color: white;
  background: #573c81;
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 57px;
  padding-right: 57px;
}

.label1 {
  color: white;
  width: 259px;
  height: 51px;
  background: #573c81;
  border-radius: 5px;
}

.circle {
  width: 40px;
  height: 40px;
  color: white;
  background: #573c81;
  border-radius: 100%;
  margin-top: 36px;
  margin-bottom: 36px;
  font-size: 36px;
}

.titleCircle {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #242424;
}
</style>