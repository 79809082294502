import axios from 'axios';

let config_token = {
    headers: {
        'Content-Type': 'multipart/form-data',
    }
}

const uploadFile = (data) => {
    let auth = axios.post(`${process.env.VUE_APP_URL_API}/uploads/single`, data, config_token);
    return auth;
}

export default {
    uploadFile,
}